import React from 'react'
import PropTypes from 'prop-types'

const Heading = ({ title, subtitle, image }) => (
    <div
        className="full-width-image margin-top-0"
        style={{
            backgroundImage: `url(${
                !!image.childImageSharp ? image.childImageSharp.fluid.src : image
            })`,
            backgroundPosition: `top left`,
            backgroundAttachment: `fixed`,
        }}
    >
        <div
            style={{
                display: 'flex',
                height: '150px',
                lineHeight: '1',
                justifyContent: 'space-around',
                alignItems: 'center',
                flexDirection: 'column',
            }}
        >
            <h1
                className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
                style={{
                    boxShadow: 'rgb(255, 255, 255, 0.5) 0.5rem 0px 0px, rgb(255, 255, 255, 0.5) -0.5rem 0px 0px',
                    backgroundColor: 'rgb(255, 255, 255, 0.5)',
                    borderRadius: '0.25em',
                    color: 'black',
                    lineHeight: '1',
                    padding: '0.25em',
                }}
            >
                {title}
            </h1>
            {subtitle ? <h3
                    className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
                    style={{
                        boxShadow: 'rgb(255, 255, 255, 0.5) 0.5rem 0px 0px, rgb(255, 255, 255, 0.5) -0.5rem 0px 0px',
                        backgroundColor: 'rgb(255, 255, 255, 0.5)',
                        borderRadius: '0.25em',
                        color: 'black',
                        lineHeight: '1',
                        padding: '0.25em',
                    }}
                >
                    {subtitle}
                </h3> : ''
            }
        </div>
    </div>
)

Heading.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    image: PropTypes.object,
}

export default Heading
